// TODO

import React from 'react';
import Quill from 'quill';
import assign from 'lodash/assign';
import type { QuillOptionsStatic } from 'quill';

const theme = 'snow';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image', 'video'],
    [{ color: [] }, { background: [] }],

    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};
const placeholder = '';

const formats = [
  'code',
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'list',
  'indent',
  'size',
  'header',
  'link',
  'image',
  'video',
  'color',
  'background',
  'clean',
];

export const useQuill = (
  options: QuillOptionsStatic | undefined = {
    theme,
    modules,
    formats,
    placeholder,
    //@ts-ignore
    readOnly,
  }
) => {
  const quillRef: React.RefObject<any> = React.useRef();

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [obj, setObj] = React.useState({
    Quill: undefined as any | undefined,
    quillRef,
    quill: undefined as Quill | undefined,
    editorRef: quillRef,
    editor: undefined as Quill | undefined,
  });

  React.useEffect(() => {
    if (!obj.Quill) {
      require('quill-mention');
      obj.Quill = require('quill') as Quill;
    }

    if (obj.Quill && !obj.quill && quillRef && quillRef.current && !isLoaded) {
      const opts = assign(options, {
        placeholder: options.placeholder,
        modules: assign({}, modules, options.modules),
        formats: options.formats || formats,
        theme: options.theme || theme,
        readOnly: options.readOnly,
      });
      const quill = new obj.Quill(quillRef.current, opts);

      setObj(assign({}, obj, { quill, editor: quill }));
    }
    setIsLoaded(true);
  }, [obj.Quill]);

  return obj;
};

export default {};
