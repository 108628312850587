import classes from './Entity.availability.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import ScheduleEvensCard from '@shared/components/molecules/ScheduleEvensCard';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import type { Timesheet } from '../PreferencesModal/PreferencesModal.schedule.availability';
import type { ReactElement } from 'react';

interface Props {
  entityData: Timesheet;
  attendee?: {
    userId: string;
    croppedImageUrl: string;
  };
  classNames?: {
    container?: string;
  };
  action?: () => ReactElement;
  onClick?: () => void;
}

const SharedAvailability = ({
  entityData,
  attendee,
  classNames,
  action,
  onClick,
}: Props): JSX.Element => {
  const { authUser } = useGetAppObject();

  return (
    <Flex
      className={cnj(classes.container, classNames?.container)}
      onClick={onClick}
    >
      <ScheduleEvensCard
        event={{
          schedulesEventType: 'MEETING',
          id: '',
          title: entityData?.title,
          attendees: [
            attendee || {
              userId: authUser?.id,
              croppedImageUrl: authUser?.imageUrl,
            },
          ],
        }}
        availabilityMeetingDuration={entityData?.duration}
        noActionButtons
        action={action}
      />
    </Flex>
  );
};

export default SharedAvailability;
