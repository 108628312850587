import classes from './ScheduleEvensCard.component.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import Typography from '@lobox/uikit/Typography';
import Avatar from '@lobox/uikit/Avatar';
import {
  schedulesDb,
  schedulesEventValues,
  useGlobalDispatch,
  useTranslation,
} from '@lobox/utils';
import AvatarsCard from '@shared/components/molecules/AvatarsCard';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import { Time } from '@shared/utils/Time';
import { useGetUserData } from '@shared/components/Organism/UnitSection/useGetUserData';
import { checkIfAllDay } from '@shared/utils/checkIfAllDay';
import ScheduleEventDateTime from './ScheduleEvensCard.dateTime';
import ScheduleEvensCardActions from './ScheduleEvensCard.actions';
import type { ISchedulesEvent } from '@lobox/utils';

interface ScheduleEvensCardProps {
  event: ISchedulesEvent;
  className?: string;
  action?: (event: ISchedulesEvent) => React.ReactElement;
  onClick?: (event: ISchedulesEvent) => void;
  disableOnClick?: boolean;
  noActionButtons?: boolean;
  availabilityMeetingDuration?: string;
}

const limit = 3;

const ScheduleEvensCard = ({
  event,
  className,
  action,
  onClick,
  disableOnClick,
  noActionButtons,
  availabilityMeetingDuration,
}: ScheduleEvensCardProps): JSX.Element => {
  const isAvailability = !!availabilityMeetingDuration;
  const { title, start, end, attendees, collaborators } = event;
  if (event && !event?.schedulesEventType)
    event.schedulesEventType = event?.type;
  const isAllDay = checkIfAllDay({ ...event, type: event?.schedulesEventType });
  const startTime = isAllDay
    ? Time.getTimeWithOffset(
        Time.convertBackFormatToFront(start),
        -Time.getCurrentTimezoneOffset()
      )
    : Time.convertBackFormatToFront(start);

  const endTime = isAllDay
    ? Time.getTimeWithOffset(
        Time.convertBackFormatToFront(end),
        -Time.getCurrentTimezoneOffset()
      )
    : Time.convertBackFormatToFront(end);

  const { t } = useTranslation();
  const globalDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();

  const names: Record<string, string> = {
    [schedulesEventValues.MEETING]: t('meeting'),
    [schedulesEventValues.REMINDER]: t('reminder'),
    [schedulesEventValues.TASK]: t('task'),
    [schedulesEventValues.HOLIDAY]: t('p_holiday'),
    [schedulesEventValues.BIRTHDAY]: t('birthday'),
  };

  const startDateLabels: any = {
    [schedulesEventValues.BIRTHDAY]: t('date'),
    [schedulesEventValues.HOLIDAY]: t('date'),
    [schedulesEventValues.REMINDER]: t('date_time'),
  };

  const isTask = event.schedulesEventType === schedulesEventValues.TASK;
  const isMeeting = event.schedulesEventType === schedulesEventValues.MEETING;
  const isReminder = event.schedulesEventType === schedulesEventValues.REMINDER;
  const hasAvatarSection = isTask || isMeeting;

  const onlyDateNoTime = [
    schedulesEventValues.HOLIDAY,
    schedulesEventValues.BIRTHDAY,
  ].includes(event.schedulesEventType as any);
  const noEnd = [
    schedulesEventValues.HOLIDAY,
    schedulesEventValues.BIRTHDAY,
    schedulesEventValues.REMINDER,
  ].includes(event.schedulesEventType as any);

  const eventIndicatorClassName = `${event.schedulesEventType}-bg`;
  const subTitle = names[event.schedulesEventType];

  const startTimeLabel = onlyDateNoTime
    ? undefined
    : Time.getFormTime(startTime)?.label;

  const endTimeLabel = noEnd ? undefined : Time.getFormTime(endTime)?.label;

  const avatars = [
    { ...event?.creator },
    ...(attendees || collaborators || []),
  ]?.filter((item) => Object?.keys(item)?.length > 0);

  const visibleAvatars = avatars.slice(0, limit);
  const remainCount =
    avatars?.length > limit ? avatars?.length - limit : undefined;
  const startDateLabel =
    startDateLabels[event.schedulesEventType] || t('start_date_time');

  const onClickHandler = () => {
    if (disableOnClick) return;
    globalDispatch({
      type: 'SET_SHOW_RIGHT_PANEL',
      payload: true,
    });
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        visibleForm: false,
        schedulesEventType: event.schedulesEventType,
        eventId: event.id,
      },
    });
    onClick?.(event);
  };
  const meetingDurationLabelForAvailability = schedulesDb.meetingDuration.find(
    (item) => item?.value === availabilityMeetingDuration
  )?.label;
  const { data: attendee }: { data: any } = useGetUserData(
    isAvailability && visibleAvatars?.[0]?.userId,
    false,
    true
  );

  return (
    <BaseButton
      onClick={onClickHandler}
      className={cnj(
        classes.scheduleEvensCardRoot,
        isAvailability && classes.scheduleEvensCardRootAvailability,
        className
      )}
      disabled={isAvailability}
    >
      <Flex className={classes.topWrapper}>
        <Flex className={classes.titleWrapper}>
          <OverflowTip
            font="700"
            height={19}
            size={16}
            color="smoke_coal"
            className={classes.title}
          >
            {title}
          </OverflowTip>
          <Typography color="muteMidGray_gray80" height={14} size={12}>
            {subTitle}
          </Typography>
        </Flex>
        {!noActionButtons && <ScheduleEvensCardActions event={event} />}
      </Flex>
      <Flex className={classes.dateTimeWrapper}>
        <Flex>
          {(!!startTime || !!startTimeLabel) && (
            <ScheduleEventDateTime
              className={classes.marginTop}
              label={isAvailability ? t('meeting_duration') : startDateLabel}
              date={startTime}
              time={startTimeLabel}
              isAvailability={isAvailability}
              text={meetingDurationLabelForAvailability}
            />
          )}

          {!isAvailability && isTask && (!!endTime || !!endTimeLabel) && (
            <ScheduleEventDateTime
              className={classes.marginTop}
              label={t('end_date_time')}
              date={endTime}
              time={endTimeLabel}
            />
          )}
        </Flex>
      </Flex>
      {hasAvatarSection && (
        <Flex className={classes.marginTop}>
          <Typography
            color="secondaryDisabledText"
            className={cnj(
              isAvailability ? classes.labelAttendee : classes.labelAttendees
            )}
            font="500"
            size={13}
            height={15}
          >
            {isTask
              ? t('collaborators')
              : isAvailability
                ? t('attendee')
                : t('attendees')}
          </Typography>

          {!isAvailability ? (
            avatars?.length > 0 ? (
              avatars?.length === 1 ? (
                <Flex className={classes.attendeeContainer}>
                  <Avatar size="md" imgSrc={avatars?.[0]?.image} />
                  <Flex className={classes.attendeeTextContainer}>
                    <Typography
                      font="700"
                      size={16}
                      color="smoke_coal"
                      isTruncated
                      lineNumber={1}
                    >
                      {avatars?.[0]?.fullName}
                    </Typography>
                    <Typography
                      size={15}
                      color="gray"
                      isTruncated
                      lineNumber={1}
                    >
                      {avatars?.[0]?.username}
                    </Typography>
                  </Flex>
                </Flex>
              ) : (
                <AvatarsCard
                  // onClick={onClick}
                  avatarProps={{
                    size: 'xs',
                    className: classes.avatar,
                  }}
                  text={
                    remainCount ? `+${remainCount} ${t('more_sm')}` : undefined
                  }
                  avatars={visibleAvatars}
                />
              )
            ) : (
              <Typography
                color="primaryDisabledText"
                font="400"
                size={14}
                height={16}
                className={classes.noPeopleText}
              >
                {isTask
                  ? t('no_collaborators_added')
                  : isMeeting
                    ? t('no_attendees_added')
                    : isReminder
                      ? t('no_assignees_added')
                      : ''}
              </Typography>
            )
          ) : (
            <Flex className={classes.attendeeContainer}>
              <Avatar size="md" imgSrc={attendee?.image} />
              <Flex className={classes.attendeeTextContainer}>
                <Typography
                  font="700"
                  size={16}
                  color="smoke_coal"
                  isTruncated
                  lineNumber={1}
                >
                  {attendee?.title}
                </Typography>
                <Typography size={15} color="gray" isTruncated lineNumber={1}>
                  {attendee?.subtitle}
                </Typography>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
      {action && <Flex className={classes.marginTop}>{action(event)}</Flex>}
      <Flex
        className={cnj(
          classes.eventIndicator,
          classes?.[eventIndicatorClassName]
        )}
      />
    </BaseButton>
  );
};

export default ScheduleEvensCard;
