import classes from './ScheduleEvensCard.dateTime.module.scss';

import React from 'react';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { formatDate } from '@lobox/utils';
import type { FC } from 'react';

interface ScheduleEvensCardProps {
  className?: string;
  label?: string;
  date: any;
  time: string;
  isAvailability?: boolean;
  text?: string;
}

const ScheduleEventDateTime: FC<ScheduleEvensCardProps> = (props) => {
  const { date, time, className, label, isAvailability, text } = props;

  return (
    <Flex className={className}>
      {label && (
        <Typography className={classes.label} font="500" size={13} height={15}>
          {label}
        </Typography>
      )}
      <Flex className={classes.dateTimeWrapper}>
        {
          <Typography color="primaryText" size={14} height={18}>
            {isAvailability ? text : !!date ? formatDate(date) : ''}
          </Typography>
        }
        {!isAvailability && time && (
          <>
            <DividerVertical className={classes.divider} />
            <Typography color="primaryText" size={14} height={18}>
              {time}
            </Typography>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ScheduleEventDateTime;
