import { isInViewport } from '.';

export function focusAndScroll(
  el: HTMLElement | Element | undefined | null,
  timeout = 300
) {
  setTimeout(() => {
    if (!el) return;
    if (isInViewport(el)) return;
    if ('focus' in el) el.focus({ preventScroll: true });
    el.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, timeout);
}
