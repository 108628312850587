import React from 'react';
import EmptyFlag from '../shared/svg/Person';
import Person from '../shared/svg/Person';
import Flex from '../Flex/Flex.component';
import Image from '../Image';

interface IRichTextUser {
  item: any;
  fillColor: string;
}
const RichTextUser = ({ item, fillColor }: IRichTextUser) => {
  const { userType, title, name, surname, username, croppedImageUrl } = item;
  const Placeholder = userType === 'PAGE' ? EmptyFlag : Person;

  return (
    <div className="qlm_listItem">
      <div className="qlm_item">
        <div className="qlm_avatar_container">
          {croppedImageUrl ? (
            <Image
              resolution="tiny"
              alt="avatar"
              src={croppedImageUrl}
              className={`qlm_avatar ${
                userType === 'PAGE'
                  ? 'avatar-square-rounded'
                  : 'avatar-circle-rounded'
              }`}
              defaultTag
            />
          ) : (
            <Flex
              className={`mention-avatar-empty-root ${
                userType === 'PAGE'
                  ? 'avatar-square-rounded'
                  : 'avatar-circle-rounded'
              }`}
            >
              <Flex className="mention-avatar-empty">
                <Placeholder fill={fillColor} />
              </Flex>
            </Flex>
          )}
        </div>
        <div className="qlm_text">
          <div className="qlm_value">
            {userType === 'PAGE' ? title : `${name} ${surname}`}
          </div>
          <div className="qlm_type">
            <div className="qlm_secondary_text">@{username}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichTextUser;
