let asyncTimeout = {};

export function debounceAsync(
  key: string,
  asyncFunc: Function,
  waitingTime: number
) {
  return async function executedFunction(...args) {
    return new Promise((resolve, reject) => {
      clearTimeout(asyncTimeout[key]);

      asyncTimeout[key] = setTimeout(async () => {
        try {
          const result = await asyncFunc(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, waitingTime);
    });
  };
}
