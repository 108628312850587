import { schedulesEventValues } from '@lobox/utils';

export const detailsLabels = {
  [schedulesEventValues.MEETING]: 'meeting_details',
  [schedulesEventValues.REMINDER]: 'reminder_details',
  [schedulesEventValues.TASK]: 'task_details',
  [schedulesEventValues.BIRTHDAY]: 'birthday_details',
  [schedulesEventValues.HOLIDAY]: 'public_holiday_details',
};
export const shareLabels = {
  [schedulesEventValues.MEETING]: 'share_meeting',
  [schedulesEventValues.REMINDER]: 'share_reminder',
  [schedulesEventValues.TASK]: 'share_task',
  [schedulesEventValues.BIRTHDAY]: 'share_birthday',
  [schedulesEventValues.HOLIDAY]: 'share_public_holiday',
};

export const removeLabels = {
  [schedulesEventValues.MEETING]: 'remove_meeting',
  [schedulesEventValues.REMINDER]: 'remove_reminder',
  [schedulesEventValues.TASK]: 'remove_task',
  [schedulesEventValues.BIRTHDAY]: 'remove_birthday',
  [schedulesEventValues.HOLIDAY]: 'remove_public_holiday',
};

export const LOGIN_FORM_AVAILABILITY_UNAUTH_ID =
  'LOGIN_FORM_AVAILABILITY_UNAUTH_ID';
