'use client';
import classes from './RichText.component.module.scss';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  removeHtmlTagsInstring,
  useClickOutside,
  useTranslation,
} from '@lobox/utils';
import cnj from '../utils/cnj';
import useTheme from '../utils/useTheme';
import type { TextProps } from '../Typography';
import Typography from '../Typography';
import Icon from '../Icon';
import { useQuill } from './RichText.useQuill';
import HashtagItem from './hashtag.component';
import UserItem from './user.component';
import { getHashtags, getPopularHashtags, getUsers } from './richTextApi';
import 'quill-mention/dist/quill.mention.css';
import 'quill/dist/quill.bubble.css';
import useCssVariables from '@shared/hooks/useCssVariables';
import ReactDOM, { flushSync } from 'react-dom';
import { debounceAsync } from '@shared/utils-pkg/utils/debounceAsync';
import { motion } from 'framer-motion';
import Flex from '../Flex';
import { focusAndScroll } from '@shared/utils-pkg/utils/focusAndScroll';
import EmojiPickerButton from '@lobox/uikit/EmojiPickerButton';

const _defaultValue = '<p><br></p>';

type RichTextProps = {
  label?: string;
  placeholderFontSize?: string;
  value?: string;
  defaultValue?: string;
  variant?: 'form-input' | 'comment-input' | 'simple';
  readOnly?: boolean;
  showEmoji?: boolean;
  onChange?: (text: string) => void;
  onEnterKeyDown?: (text: string) => void;
  helperText?: string;
  isEnabledMention?: boolean;
  isEnabledHashtags?: boolean;
  disableNewLineWithEnter?: boolean;
  changeWithDebounce?: boolean;
  replyMention?: {
    id: string;
    username: string;
    fullName?: string;
  };
  defaultHashtag?: boolean;
  fixMentionDropdown?: boolean;
  mentionDropDownPosition: 'top' | 'bottom';
  [key: string]: any;
  emojiClassName?: string;
  spaceFromEmoji?: boolean;
  maxLength?: number;
  labelProps?: TextProps;
  noBorder?: boolean;
  emojiProps?: any;
  disabledEmoji?: boolean;
};

const RichText = (
  {
    label,
    placeholderFontSize,
    variant = 'form-input',
    showEmoji = true,
    className,
    value = '',
    defaultValue,
    onChange,
    onEnterKeyDown,
    error,
    isFocus,
    disabled,
    readOnly,
    onFocus,
    onBlur,
    helperText,
    isEnabledHashtags = true,
    isEnabledMention = true,
    changeWithDebounce = true,
    replyMention,
    fixMentionDropdown = true,
    mentionDropDownPosition,
    disableNewLineWithEnter = false,
    emojiClassName,
    defaultHashtag,
    spaceFromEmoji,
    maxLength,
    labelProps = {},
    noBorder,
    emojiProps,
    disabledEmoji,
  }: RichTextProps,
  ref: any
): JSX.Element => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const LimitCharacterToChangeSize = 120;
  const [focusClassToLabel, setFocusClassToLabel] = useState(false);
  const [isSmallContentFontSize, setIsSmallFontSize] = useState(
    value?.length >= LimitCharacterToChangeSize
  );

  const [isFocusedInContainer, setIsFocusedInContainer] = useState(false);
  const listRef = useRef<HTMLElement>();

  const clickOutsideList = React.useCallback(() => {
    listRef.current?.remove();
  }, []);

  useClickOutside(listRef, {
    onClickOutside: clickOutsideList,
  });

  const modules = {
    // toolbar: [
    //   ['bold', 'italic', 'underline', 'strike'],
    //   [{ align: [] }],

    //   [{ list: 'ordered' }, { list: 'bullet' }],
    //   [{ indent: '-1' }, { indent: '+1' }],

    //   [{ size: ['small', false, 'large', 'huge'] }],
    //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
    //   ['link', 'image', 'video'],
    //   [{ color: [] }, { background: [] }],

    //   ['clean'],
    // ],
    toolbar: false,
    clipboard: true,
    // magicUrl: true,
    mention: {
      onClose: () => {
        // removeArrow();
      },
      onOpen: () => {
        // listRef.current = document.querySelector(classes.mentionContainer);
        // if (variant === 'comment-input') {
        //   listRef.current.style.width = '100%';
        // }
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö_]*$/,
      // allowedChars: /^[a-zA-Z0-9_]*$/,
      mentionDenotationChars:
        isEnabledHashtags && isEnabledMention
          ? ['@', '#']
          : isEnabledMention
            ? ['@']
            : isEnabledHashtags
              ? ['#']
              : undefined,
      positioningStrategy: fixMentionDropdown ? 'fixed' : 'relative',
      defaultMenuOrientation: mentionDropDownPosition,
      mentionContainerClass: cnj(
        classes.mentionContainer,
        variant === 'comment-input' && classes.commentMention
      ),

      renderItem: (item, searchTerm) => {
        if (searchTerm === '@') {
          return renderToString(
            <UserItem fillColor={theme.colors.colorIconEighth} item={item} />
          );
        }
        return renderToString(<HashtagItem value={item.value} />);
      },
      // @ts-ignore
      source: debounceAsync(
        'richtext-mention-hashtag',
        async (searchTerm, renderItem, mentionChar) => {
          let values;
          if (mentionChar === '@') {
            try {
              const d = await getUsers({
                text: searchTerm,
              });
              values = d?.map((x) => {
                const { username } = x;
                const value =
                  x.userType === 'PERSON'
                    ? `${x.name} ${x.surname}`
                    : x.title || username;

                return {
                  ...x,
                  value: JSON.stringify({
                    value,
                    username,
                    userType: x?.userType,
                  }),
                };
              });
            } catch (e) {
              console.log(e);
              values = [];
            }
          }
          if (mentionChar === '#') {
            try {
              if (searchTerm?.length)
                values = await getHashtags({
                  text: searchTerm,
                });
              else {
                values = await getPopularHashtags();
              }
            } catch (e) {
              console.log(e);
              values = [];
            }
          }
          renderItem(values, mentionChar);
        },
        100
      ),
      // @ts-ignore
      onSelect(item, insertItem) {
        let { value } = item;
        try {
          value = JSON.parse(value);
        } catch (err) {}
        insertItem({
          ...item,
          userid: item.id,
          value: value.value || value,
          username: value.username || '',
          denotationChar:
            item.denotationChar === '@' ? '' : item.denotationChar,
          userType: value?.userType,
        });
      },
    },
    // hashtagFinder: true,
  };

  const { quill, quillRef, Quill } = useQuill({
    theme: 'bubble',
    modules,
    formats: ['mention'],
    placeholder: variant === 'form-input' ? '' : label,
    readOnly: readOnly || disabled,
  });

  if (Quill && !quill) {
    const Clipboard = Quill.import('modules/clipboard');
    Quill.register('modules/clipboard', Clipboard);
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      quill?.focus();
    },
    quill,
    onChooseEmoji: chooseEmoji,
  }));

  React.useEffect(() => {
    if (quill) {
      if (defaultValue?.length) {
        if (variant === 'form-input') {
          setFocusClassToLabel(true);
        }
        const delta = quill.clipboard.convert(defaultValue);
        quill.setContents(delta, 'api');

        handleTextFontSize();
      }
    }
  }, [quill, defaultValue]);

  React.useEffect(() => {
    if (quill) {
      // @ts-ignore
      if (value) {
        if (variant === 'form-input') {
          setFocusClassToLabel(true);
        }

        const delta = quill.clipboard.convert(value);
        quill.setContents(delta, 'api');
      }
      quillRef.current.firstChild.onfocus = () => {
        setFocusClassToLabel(true);
        onFocus?.(true);
      };

      quillRef.current.addEventListener('focusout', (e) => {
        onFocus?.(false);
        onBlur?.(e);
        const value = quillRef.current?.innerText;
        if (!value?.length) setFocusClassToLabel(false);
      });

      quill.on('text-change', handleValueChange);

      if (isFocus) {
        quill?.focus();
        const length = quill.getLength();
        if (length) quill.setSelection(length + 2, Quill.sources.API);
      }
      if (disableNewLineWithEnter) disableEnterKey();
      quill.root.setAttribute('spellcheck', 'false');
    }

    return () => {
      // @ts-ignore
      quill && quill.off('text-change');
    };
  }, [quill]);

  const disableEnterKey = () => {
    quill.keyboard.bindings[13].unshift({
      key: 13,
      handler: (range, context) => {
        onEnterKeyDown?.(getEditorText());
        return false;
      },
    });
  };

  const richTextEndAnchorRef = useRef<HTMLDivElement>();

  const insertMention = (replyUser) => {
    if (quill?.selection) {
      const range = quill.selection.savedRange; // cursor position

      if (!range || range.length != 0) return;
      const position = range.index;

      quill.insertEmbed(
        position,
        'mention',
        {
          denotationChar: replyUser.fullName ? '' : '@',
          index: 0,
          id: `mentionedSpan${replyUser.id}`,
          userid: replyUser.id,
          value: replyUser.fullName || replyUser.username,
          username: replyUser.username,
          isReplyToMention: true,
        },
        Quill.sources.API
      );
      quill.insertText(position + 1, ' ', Quill.sources.API);

      focusAndScroll(richTextEndAnchorRef.current);

      setTimeout(() => {
        quill.setSelection(position + 2, Quill.sources.API);
      }, 500);
    }
  };

  const refMention = useRef('');
  const containerRef = useRef(null);

  useEffect(() => {
    if (replyMention && quill) {
      const mentionSpan = containerRef.current.querySelectorAll(
        `span[data-id="mentionedSpan${refMention.current.id}"]`
      );
      if (mentionSpan.length) {
        const ele = mentionSpan[0];
        ele.dataset.id = `mentionedSpan${replyMention.id}`;
        ele.dataset.userid = replyMention.id;
        ele.dataset.value = mentionSpan;
        ele.innerHTML = `
          <span contenteditable="false">
            ${
              replyMention.fullName
                ? ''
                : '<span class="ql-mention-denotation-char">@</span>'
            }
            ${replyMention.fullName || replyMention.username}
          </span>
        `;
      } else {
        insertMention(replyMention);
      }
      refMention.current = replyMention;
    }
  }, [replyMention, quill]);

  useEffect(() => {
    if (defaultHashtag && quill && !replyMention && !defaultValue) {
      const value = `<p><br/></p>`;
      const delta = quill.clipboard.convert(value);

      quill.setContents(delta, 'silent');
      if (quill?.selection) {
        const range = quill.selection.savedRange; // cursor position

        if (!range || range.length != 0) return;
        const position = range.index;
        quill.insertEmbed(
          position + 1,
          'mention',
          {
            denotationChar: '#',
            index: 0,
            id: defaultHashtag,
            value: defaultHashtag,
          },
          Quill.sources.API
        );
        quill.setSelection(0, Quill.sources.API);
      }
    }
  }, [defaultHashtag, quill]);

  const onChangeWithDebounce = debounceAsync(
    'richtext-input',
    (inputValue) => onChange?.(inputValue),
    250
  );

  const getEditorText = () =>
    !quill.getText().split('\n').join('').length ? '' : quill.root.innerHTML;

  const handleTextFontSize = () => {
    const text = quillRef.current?.innerText;
    if (text.length >= LimitCharacterToChangeSize) {
      setIsSmallFontSize(true);
    } else {
      setIsSmallFontSize(false);
    }
  };

  function handleValueChange() {
    // @ts-ignore
    const innerText = quillRef.current?.innerText;
    // const { ops } = quill.getContents();
    // const value = ops[0].insert;
    // @ts-ignore
    const contentPureLength = removeHtmlTagsInstring(
      quillRef.current?.innerText
    )?.length;
    const isExceeding = contentPureLength > maxLength;
    if (isExceeding) {
      const diff = contentPureLength - maxLength;
      quill.deleteText(contentPureLength - diff, diff);
    }

    const countChars = innerText?.length;
    if (variant === 'form-input' && countChars > 1) {
      setFocusClassToLabel(true);
    }
    handleTextFontSize();
    // @ts-ignore
    const returnValue = getEditorText();
    const v = returnValue === _defaultValue ? '' : returnValue;
    if (changeWithDebounce) onChangeWithDebounce(v);
    else onChange(v);
  }

  const convertHtml = (html) => {
    const linkStart = `<a href="http://help.com" rel="noopener noreferrer" target="_blank">`;
    const linkEnd = `</a>`;

    // remove anchor tag around the hashtag value
    // in order to identify hashtag properly
    let modHtml = html.replace(linkStart, '');
    modHtml = modHtml.replace(linkEnd, '');
    const blurtHashtags = modHtml.match(/(?:^|\B)#[a-zA-Z][\w]{1,19}\b/g);

    if (blurtHashtags) {
      // rebuild link with anchor tag
      const link = `${linkStart}${blurtHashtags[0]}${linkEnd}`;
      modHtml = modHtml.replace(blurtHashtags[0], link);

      setTimeout(() => {
        const delta = quill.clipboard.convert(modHtml);
        quill.setContents(delta, 'silent');
      }, 0);
    }
  };

  function handleClick() {
    quill.focus();
    setFocusClassToLabel(true);
    onFocus?.(true);
  }

  function handleFocus() {
    setIsFocusedInContainer(() => true);
  }

  function handleBlur() {
    setIsFocusedInContainer(() => false);
  }

  function chooseEmoji(value: any) {
    // @ts-ignore
    const range = quill.getSelection(true);
    if (range) {
      // @ts-ignore
      quill.deleteText(range.index, range.length);
    }
    quill.insertText(range ? range.index : 0, value.native);
  }

  const _val = quillRef?.current?.innertText;
  const labelColor = error ? 'error' : isFocus ? 'brand' : 'border';

  const styles = useCssVariables({
    scope: classes.richTextEditor,
    variables: {
      placeholderFontSize: `${placeholderFontSize}px`,
    },
  });

  return (
    <>
      {styles}
      <div
        aria-hidden="true"
        ref={containerRef}
        className={cnj(
          classes.richTextEditor,
          disabled && classes.richTextEditorDisabled,
          variant === 'form-input' || variant === 'comment-input' || readOnly
            ? classes.longText
            : isSmallContentFontSize
              ? classes.longText
              : classes.shortText,
          !noBorder &&
            variant === 'form-input' &&
            !readOnly &&
            classes.borderStyle,
          !noBorder &&
            variant === 'form-input' &&
            !readOnly &&
            isFocus &&
            classes.focusedBorder,
          !noBorder &&
            variant === 'form-input' &&
            !readOnly &&
            error &&
            classes.errorBorder,
          !noBorder &&
            variant === 'comment-input' &&
            !readOnly &&
            classes.commentInputStyle,
          disabled && classes.disabledStyle,
          readOnly && classes.readOnlyStyle,
          isFocusedInContainer && !disabled && !readOnly && 'focused',
          className
        )}
        onClick={handleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {variant === 'form-input' && (
          <label
            className={cnj(
              classes.floatingLabel,
              focusClassToLabel ? classes.floatingLabelFocus : null
            )}
          >
            <Typography
              size={15}
              font="400"
              color={labelColor}
              isTruncated={focusClassToLabel}
              {...labelProps}
            >
              {label}
            </Typography>
          </label>
        )}
        {error && variant === 'form-input' && (
          <Icon
            type="fas"
            name="exclamation-circle"
            className={cnj(classes.bell, classes.toggle, classes.cursorDefault)}
          />
        )}
        <div
          ref={quillRef}
          className={cnj(
            'richtext_editor',
            spaceFromEmoji && classes.emojiPadding
          )}
        />
        <Flex
          ref={richTextEndAnchorRef}
          className={classes.richTextEndAnchor}
        />

        {!disabledEmoji && (
          <motion.div
            className={cnj(
              classes.fontContainer,
              variant === 'comment-input' && classes.commentInputEmoji,
              emojiClassName
            )}
            animate={showEmoji ? 'visible' : 'hidden'}
            initial={showEmoji ? 'visible' : 'hidden'}
            variants={{
              hidden: {
                opacity: 0,
                visibility: 'hidden',
                transform: 'translateY(-150%)',
              },
              visible: {
                opacity: 1,
                visibility: 'visible',
                transform: 'translateY(-50%)',
              },
            }}
            transition={{ duration: 0.3 }}
            data-name="pass-click"
          >
            <EmojiPickerButton
              onEmojiSelect={chooseEmoji}
              emojiClassName={emojiClassName}
              placement="top-end"
              emojiProps={emojiProps}
            />
          </motion.div>
        )}
      </div>

      {(!!error || helperText) && (
        <Typography
          size={13}
          height={15}
          color="border"
          className={cnj(classes.helperText, error && classes.errorText)}
        >
          {error ? t(error) : helperText}
        </Typography>
      )}
      {maxLength && (
        <Typography
          size={13}
          height={15}
          color="border"
          textAlign="right"
          className={classes.maxLength}
        >{`${
          removeHtmlTagsInstring(value?.toString())?.length || 0
        }/${maxLength}`}</Typography>
      )}
    </>
  );
};

export default forwardRef(RichText);

function renderToString(element: React.ReactElement) {
  const container = document.createElement('div');
  const root = ReactDOM.createRoot(container);
  flushSync(() => {
    root.render(element);
  });
  return container.innerHTML;
}
