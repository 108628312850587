import classes from './index.module.scss';
import React, { ComponentProps, useRef } from 'react';

import EmojiPicker, {
  Categories,
  EmojiStyle,
  SuggestionMode,
  Theme,
} from 'emoji-picker-react';
import IconButton from '@shared/uikit/Button/IconButton';
import cnj from '@shared/uikit/utils/cnj';
import PopperMenu from '@lobox/uikit/PopperMenu';
import useTheme from '@lobox/uikit/utils/useTheme';
import { useTranslation } from '@lobox/utils';

type Props = {
  onEmojiSelect?: any;
  emojiPickerPosition?: 'default' | 'matchWidth';
  emojiClassName?: string;
  emojiTooltip?: string;
  placement?: ComponentProps<typeof PopperMenu>['placement'];
  offsetY?: ComponentProps<typeof PopperMenu>['offsetY'];
  emojiProps?: any;
};

const EmojiPickerButton = ({
  onEmojiSelect,
  emojiClassName,
  emojiTooltip,
  placement = 'top-start',
  offsetY,
  emojiProps,
}: Props) => {
  const popperRef = useRef<{ close: Function; open: Function }>();
  const { isDark } = useTheme();
  const { t } = useTranslation();

  function chooseEmoji(val: any) {
    const value = {
      ...val,
      native: val.emoji,
    };

    popperRef?.current?.close?.();
    onEmojiSelect?.(value);
  }
  return (
    <PopperMenu
      noDrawer={true}
      hoverDelay={0}
      disableCloseOnClickInSide
      buttonComponent={(visible: boolean) => (
        <IconButton
          className={cnj(emojiClassName)}
          tooltip={visible ? emojiTooltip : undefined}
          name="smile"
          size="sm16"
          type="far"
          colorSchema="transparent"
          {...emojiProps}
        />
      )}
      menuClassName={classes.menuClassName}
      placement={placement}
      offsetY={offsetY}
      ref={popperRef}
      closeOnScroll
    >
      <div id="emoji-piker-popup">
        <EmojiPicker
          className={classes.lbEmojiPicker}
          suggestedEmojisMode={SuggestionMode.RECENT}
          emojiStyle={EmojiStyle.NATIVE}
          height={360}
          skinTonesDisabled
          previewConfig={{ showPreview: false }}
          theme={isDark ? Theme.DARK : Theme.LIGHT}
          onEmojiClick={chooseEmoji}
          lazyLoadEmojis
          searchDisabled
          categories={[
            // {
            //   name: 'suggested',
            //   category: Categories.SUGGESTED,
            // },
            {
              name: 'custom',
              category: Categories.CUSTOM,
            },
            {
              name: t('smileys_people'),
              category: Categories.SMILEYS_PEOPLE,
            },
            {
              name: t('animals_nature'),
              category: Categories.ANIMALS_NATURE,
            },
            {
              name: t('food_drink'),
              category: Categories.FOOD_DRINK,
            },
            {
              name: t('travel_places'),
              category: Categories.TRAVEL_PLACES,
            },
            {
              name: t('activities'),
              category: Categories.ACTIVITIES,
            },
            {
              name: t('objects'),
              category: Categories.OBJECTS,
            },
            {
              name: t('symbols'),
              category: Categories.SYMBOLS,
            },
            {
              name: t('flags'),
              category: Categories.FLAGS,
            },
          ]}
        />
      </div>
    </PopperMenu>
  );
};

export default EmojiPickerButton;
