import React from 'react';
// import Flex from '../Flex';

interface IRichTextHashtag {
  value: string;
}
const RichTextHashtag = ({ value }: IRichTextHashtag) => {
  return (
    <div className="qlm_hashtag">
      <div className="qlm_listItem">
        <div className="qlm_item">
          <div className="qlm_text">
            <div className="qlm_value">#{value}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichTextHashtag;
