import classes from './ScheduleEvensCard.actions.module.scss';

import React from 'react';
import Divider from '@lobox/uikit/Divider';
import IconButton from '@lobox/uikit/Button/IconButton';
import PopperItem from '@lobox/uikit/PopperItem';
import PopperMenu from '@lobox/uikit/PopperMenu';
import {
  preventClickHandler,
  useGlobalDispatch,
  useTranslation,
} from '@lobox/utils';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import type { ISchedulesEvent } from '@lobox/utils';
import {
  detailsLabels,
  removeLabels,
  shareLabels,
} from '@shared/constants/schedules';
import useDeleteScheduleEvent from '@shared/hooks/useDeleteScheduleEvent';

interface ScheduleEvensCardProps {
  event: ISchedulesEvent;
}

const ScheduleEvensCardActions = ({
  event,
}: ScheduleEvensCardProps): JSX.Element => {
  const { t } = useTranslation();
  const globalDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();

  const { deleteHandler: deleteScheduleEvent, onDeletionSuccessHandler } =
    useDeleteScheduleEvent(event?.schedulesEventType, event?.id);

  const detailsLabel = t(detailsLabels[event.schedulesEventType]);
  const shareLabel = t(shareLabels[event.schedulesEventType]);
  const removeLabel = t(removeLabels[event.schedulesEventType]);

  const openDetailModal = () => {
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        visibleForm: false,
        schedulesEventType: event.schedulesEventType,
        eventId: event.id,
      },
    });

    globalDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: {
        isOpen: false,
      },
    });
  };

  const deleteHandler = (e: any, event: ISchedulesEvent) => {
    preventClickHandler(e);
    deleteScheduleEvent({
      eventId: event.id,
      eventTitle: event.title,
      schedulesEventType: event.schedulesEventType,
      onSuccess: onDeletionSuccessHandler,
    });
  };

  const onShareEvent = () => {
    globalDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: {
        isOpen: true,
        tabs: ['copy_link', 'share_via_message', 'share_via_email'],
        entityData: {
          attachment: {
            type: event?.schedulesEventType,
            // openDetailModal requires ScheduleProvider
            // which is not accessible inside
            // share modal so we need to pass it there
            data: { ...event, openDetailModal },
          },
        },
      },
    });
  };

  const toggleReportModal = () => {
    globalDispatch({
      type: 'TOGGLE_REPORT_MODAL',
      payload: {
        isOpen: true,
        data: {
          entityType: 'meeting',
          entityId: event.id,
        },
      },
    });
  };

  const actions = [
    {
      icon: 'info-circle',
      label: detailsLabel,
      onClick: openDetailModal,
    },
    event?.schedulesEventType !== 'REMINDER' && {
      icon: 'share-alt',
      label: shareLabel,
      onClick: onShareEvent,
    },
    event?.schedulesEventType !== 'HOLIDAY' && {
      icon: 'trash',
      label: removeLabel,
      onClick: (e: any) => deleteHandler(e, event),
    },
    { icon: 'divider' },
    {
      icon: 'comment-alt-edit',
      label: t('send_feedback'),
    },
    event?.schedulesEventType !== 'HOLIDAY' && {
      icon: 'pennant',
      label: t('report'),
      onClick: toggleReportModal,
    },
  ]?.filter(Boolean);

  return (
    <PopperMenu
      placement="bottom-end"
      closeOnScroll
      buttonComponent={<IconButton type="fas" name="ellipsis-h" size="sm" />}
    >
      <>
        {actions.map(({ icon, label, onClick }) =>
          icon === 'divider' ? (
            <Divider key={icon} className={classes.divider} />
          ) : (
            <PopperItem
              key={icon}
              onClick={onClick}
              iconName={icon}
              iconType="far"
              label={label}
            />
          )
        )}
      </>
    </PopperMenu>
  );
};

export default ScheduleEvensCardActions;
