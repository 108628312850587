import RichText from './RichText.component';
import RichTextView from './RichTextView';
import {
  getRichTextMentions,
  getRichTextHashtags,
  detectNewHashtags,
  sanitizeEmojies,
} from './richTextApi';

export {
  getRichTextMentions,
  getRichTextHashtags,
  RichTextView,
  detectNewHashtags,
  sanitizeEmojies,
};

export default RichText;
